import {
	postRequest
} from "@/http/http.js"
export const token = query => postRequest('/http/qiniuToken', query);

// import request from '@/utils/request'
// export function token() {
//   return request({
//     url:'/file/upToken',
//     method:'get',
//   })
// }