
import {
	getRequest,
	postRequest
} from "@/http/http.js"

// 文件表格
export const documentListInfo = query => postRequest('/admin/documentListInfo', query);

// 文件表格删除
export const documentInfoDel = query => postRequest('/admin/documentInfoDel', query);

// 文件添加
export const documentInfoAdd = query => postRequest('/admin/documentInfoAdd', query);



