<template> 
	<div>
		<el-upload :action="useQiniu?qiniuUploadUrl:minioUploadUrl" :data="useQiniu?dataObj:null" list-type="picture"
			:multiple="false" :show-file-list="showFileList" :file-list="fileList" :before-upload="beforeUpload"
			:on-remove="handleRemove" :on-success="handleUploadSuccess" :on-preview="handlePreview">
			<el-button size="small" type="primary">点击上传</el-button>
			<div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .xls</div>
		</el-upload>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="fileList[0].url" alt="">
		</el-dialog>

	</div>
</template>
<script>
	import {
		token
	} from '@/api/qiniu.js'
	export default {
		name: 'singleUploadQiniu',
		props: {
			value: String,
		},
		computed: {
			imageUrl() {
				return this.value;
			},
			imageName() {
				if (this.value != null && this.value !== '') {
					return this.value.substr(this.value.lastIndexOf("/") + 1);
				} else {
					return null;
				}
			},
			fileList() {
				return [{
					name: this.imageName,
					url: this.imageUrl
				}]
			},
			showFileList: {
				get: function() {
					return this.value !== null && this.value !== '' && this.value !== undefined;
				},
				set: function(newValue) {}
			}
		},
		data() {
			return {
				dataObj: {
					token: '',
					serverUrl: '',
					bucketname: ''
				},
				dialogVisible: false,
				useQiniu: true, //使用qiniu->true;使用MinIO->false	
				qiniuUploadUrl: 'https://up-z1.qiniup.com', //http://image.zrms.com.cn    https://up-z0.qiniup.com
				minioUploadUrl: 'http://localhost:8080/minio/upload',
			};
		},
		mounted() {
			this.tokenWay();
		},
		methods: {
			tokenWay() {
				this.$post('/http/qiniuToken', {}).then((res) => {
					if (res) {
						this.dataObj.token = res.data.token;
						// this.dataObj.key = res.data.key;
						this.dataObj.serverUrl = res.data.domain;
						this.dataObj.bucketname = res.data.bucket;
					}
				}).catch((error) => {
					console.log(error)
				})
			},
			emitInput(val) {
				this.$emit('input', val)
			},
			handleRemove(file, fileList) {
				this.emitInput('');
			},
			handlePreview(file) {
				this.dialogVisible = true;
			},
			beforeUpload(file) {
				console.log(file, 'file-------')
				this.dataObj.key = new Date().getTime() + file.name;

				this.$message.success('上传成功');

				let _self = this;
				if (!this.useQiniu) {
					//不使用qiniu不需要获取策略
					return true;
				}
				return new Promise((resolve, reject) => {
					token().then(response => {
						_self.dataObj.token = response.data.token;
						// _self.dataObj.key = response.data.key;
						_self.dataObj.serverUrl = response.data.domain;
						_self.dataObj.bucketname = response.data.bucket;

						// _self.dataObj.key = new Date().getTime() + file.name;
						resolve(true)
					}).catch(err => {
						reject(false)
					})
				})
			},
			handleUploadSuccess(res, file) {
				this.showFileList = true;

				this.fileList.pop();
				let url = this.dataObj.serverUrl + '/' + this.dataObj.key;

				console.log(res, url, '-----------------')
				if (!this.useQiniu) {
					//不使用qiniu直接获取图片路径
					url = res.data.url;
				}
				this.fileList.push({
					name: file.name,
					url: url
				});

				console.log(this.fileList, 'this.fileList-文件')

				this.emitInput(this.fileList[0].url);
			}
		}
	}
</script>
<style>

</style>