<template>
	<div class="bigBox">
		<h2>文件管理</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-input v-model="form.keyword" prefix-icon="Search" placeholder="搜索文件名称"
								class="namePut"></el-input>
							<el-button type="primary" icon="Search" class="searchs" @click="searchs">
								<i class="el-icon-edit"></i>搜索
							</el-button>
							<el-button class="agains" @click="resetting">重置
							</el-button>
						</div>
						<div class="row-me row-center addDiv" v-if="this.powerListName.includes('新增按钮')"
							@click="flieUpload">
							<img src="../../assets/img/icon_scwj.png" class="iconImg" />&nbsp;
							<span class="youzhi">上传文件</span>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox  margin-top20">
				<el-table ref="singleTable" :data="fileListTab" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column property="name" label="文件名称" align="center">
					</el-table-column>
					<el-table-column property="remarks" label="文件描述" align="center">
					</el-table-column>
					<el-table-column property="file_format" label="文件格式" align="center">
					</el-table-column>
					<el-table-column property="create_time" label="创建时间" align="center">
					</el-table-column>
					<el-table-column label="操作" align="left" width="200">
						<template #default="scope">
							<el-button type="text" size="small" @click="handleClickEdit(scope.row)">下载
							</el-button>
							<el-button type="text" size="small" v-if="this.powerListName.includes('编辑按钮')"
								@click="flieUpload(scope.row)">编辑
							</el-button>
							<el-button type="text" size="small" v-if="this.powerListName.includes('删除按钮')"
								@click="handleClickDel(scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="row-me row-center flex-end1 margin-top10" v-if="fileListTab.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>

		</div>
		<!-- 上传文件弹窗 -->
		<el-dialog v-model="dialogUp" v-if="dialogUp" :title="files" width="30%">
			<el-form :model="forms" label-width="120px">
				<el-form-item label="文件名称">
					<el-input v-model="forms.name" placeholder="请输入" style="width: 400px;" />
				</el-form-item>
				<el-form-item label="文件描述">
					<el-input v-model="forms.remarks" placeholder="请输入" style="width: 400px;" type="textarea"
						:rows="3" />
				</el-form-item>
				<el-form-item label="上传文件">
					<singleUploadQiniu v-model="forms.file_url" @input="getName"></singleUploadQiniu>

					<!-- <el-upload class="upload-demo " ref="upload" :action="forms.file_url" :auto-upload="false">
						<el-button slot="trigger" size="small" type="primary">上传文件</el-button>
						<div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .xls</div>
					</el-upload> -->
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogUp = false" class="width100height40right10">取消</el-button>
					<el-button type="primary" @click="dialogUpOk">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		documentListInfo,
		documentInfoDel,
		documentInfoAdd
	} from "@/api/fileManagement/fileManagement.js"
	import {
		ElMessage
	} from 'element-plus'
	import singleUploadQiniu from '@/components/singleUploadQiniu.vue' //上传文件
	export default {
		components: {
			singleUploadQiniu
		},
		data() {
			return {
				dialogUp: false,
				fileList: [],
				form: {
					keyword: "",
					region1: "",
					region2: "",
				},
				forms: {
					name: "", //文件名称
					file_url: "", //文件链接
					remarks: "", //描述
					file_format: "", //文件扩展名
					id: ''
				},
				fileListTab: [],
				allListNumber: 10,
				pageCurre: 1,
				pageNo: 1,
				total: 0,
				powerListName: [], //权限按钮
			}
		},
		mounted() {
			var list = JSON.parse(sessionStorage.getItem('menuArrayRegulate'));
			console.log(list, 'listlist');
			var nameList = [];
			for (var i = 0; i < list.length; i++) {
				if (list[i].name == "文件管理") {
					nameList = list[i].children;
				}
			}

			if (nameList != undefined) {
				for (var i = 0; i < nameList.length; i++) {
					this.powerListName.push(nameList[i].name)
				}
			}
			console.log(this.powerListName, '最后按钮')
			this.getFileTab()
		},
		methods: {
			getName(data) {
				this.forms.file_url = data;
				var val = data.split(".");
				this.forms.file_format = val[val.length - 1];
				console.log(val, 'val------');
				// file_format	//文件扩展名
				// alert(data); //此时的data就是封装的方法中传过来的label
			},
			// 文件新建编辑
			flieUpload(row) {
				if (row.id) {
					this.files = "编辑文件";
					this.forms = row;
				} else {
					// forms: {
					// 	name: "", //文件名称
					// 	file_url: "", //文件链接
					// 	remarks: "", //描述
					// 	file_format: "", //文件扩展名
					// },
					this.forms.name = '';
					this.forms.file_url = '';
					this.forms.remarks = '';
					this.forms.file_format = '';
					this.forms.id = '';
					this.files = "上传文件";
				}
				this.dialogUp = true;
			},
			// 弹框确定
			dialogUpOk() {
				// const res = await documentInfoAdd(this.forms).then((res) => {
				// 	if (res) {
				// 		// this.bus.$emit('importSuccess', 'refresh')
				// 	}
				// }).catch((error) => {
				// 	console.log(error)
				// })
				this.$post('/admin/documentInfoAdd', this.forms).then((res) => {
					if (res) {
						this.getFileTab();
						if (this.forms.id != '') {
							this.$message.success('编辑成功');
						} else {
							this.$message.success('添加成功');
						}
						this.dialogUp = false;
					}
				}).catch((error) => {
					console.log(error)
				})
			},
			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getFileTab()
			},
			async getFileTab() {
				const res = await documentListInfo({
					keyword: this.form.keyword,
					page: this.pageNo,
				})
				this.fileListTab = res.data.listInfo;
				this.total = res.data.allListNumber;
				this.pageCurre = res.data.page;
			},
			// 搜索
			searchs() {
				this.getFileTab()
			},
			// 重置
			resetting() {
				this.pageNo = 1;
				this.form.keyword = '';
				this.getFileTab()
			},
			// // 删除
			async handleClickDel(row) {
				const res = await documentInfoDel({
					id: row.id
				})
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				this.getFileTab()
			},
			// 点击下载
			handleClickEdit(row) {
				console.log(row, 'rowrowrow');
				window.open(row.file_url);
			},

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.namePut {
		/deep/.el-input__wrapper {
			width: 360px;
			height: 40px;
		}
	}

	.addDiv {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 7px 10px;
		cursor: pointer;

		.iconImg {
			width: 26px;
			height: 26px;
		}

		.youzhi {
			font-size: 18px;
		}
	}

	.agains {
		padding: 0 31px !important;
		margin-left: 10px !important;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-upload {
		flex-direction: column;
		align-items: flex-start;
	}

	::v-deep .el-upload__tip {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 21px;
		color: #9D9FA3;
	}

	::v-deep .el-form-item__label {
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 21px;
		color: #252630;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}

	/deep/.el-button+.el-button {
		margin-left: 0px;
	}
</style>